import React from 'react'

import Register from '../templates/register'

import AuthComponent from '../components/authComponent'
import Layout from '../components/layout'

const RegisterPage = ({
    location
}) => {
    return (
        <AuthComponent> 
            <Layout>
                <Register/>
            </Layout>
        </AuthComponent>
    )
}

export default RegisterPage